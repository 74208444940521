import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.isRemoved),expression:"!isRemoved"}]},'v-card',_vm.$attrs,false),_vm.$listeners),[_c(VCardTitle,[_vm._t("title",function(){return [_vm._v(" 历史账单 ")]}),_c(VSpacer)],2),_c(VCardText,{staticClass:"app-card-body"},[_vm._t("default",function(){return [[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.queueList,"options":_vm.options,"server-items-length":_vm.totalLists,"items-per-page":10,"loading":_vm.loading,"no-data-text":"暂无数据","no-results-text":"未找到相关数据","loading-text":"加载中，请稍等..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.splitRatio",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.splitRatio)+"%")])]}},{key:"item.distributableIncome",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.distributableIncome&&item.splitRatio?(item.distributableIncome*(item.splitRatio/100)).toFixed(2):'0'))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"noUnderline",attrs:{"to":{name:'billSettlement-report',query:{id:item.id} }}},[_vm._v(" 查看报告 ")])]}}],null,true)})]]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }